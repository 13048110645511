import HTTP from './http/HTTP';
import { politicians as politiciansCache } from './util/cache';

/**
 * Constructs the API service
 * @param  {*}      _           Unused immstruct reference
 * @param  {String} baseUrl     The base url
 * @param  {String} cdnBaseUrl  The CDN base url
 * @param  {Boolean} enabled    Set to `true` when the API is enabled
 * @return {Object}             The API service
 */
const api = (_, { baseUrl, cdnBaseUrl, enabled }) => {
  const httpCDN = HTTP(cdnBaseUrl),
    httpApi = HTTP(baseUrl),
    wrap = (httpMethod, enabled) => (enabled ? httpMethod : () => Promise.reject(new Error('API Disabled'))),
    xGet = wrap(httpCDN.get, enabled),
    // To be GDPR-compliant, we send all post, put, patch and delete requests directly to our api
    xPost = wrap(httpApi.post, enabled),
    xPut = wrap(httpApi.put, enabled),
    xDelete = wrap(httpApi.delete, enabled);

  /**
   * Performs HTTP GET and caches valid response.
   * @param {Cache} cache - Cache instance.
   * @param {String} key - Cache key.
   * @param {String} path - URL path.
   * @param {Array} [headers]
   * @returns {Promise}
   */
  const cachingGet = (cache, key, path, headers) => {
    const cacheEntry = cache.get(key);

    if (cacheEntry) {
      return Promise.resolve(cacheEntry);
    } else {
      return xGet(path, headers).then((data) => {
        cache.add(key, data);

        return data;
      });
    }
  };

  const methods = {
    /**
     * Get data about app versions.
     * @param {Array} [headers] - Optional request headers
     * @return {Promise}
     */
    getAppVersions: (headers) => xGet('/values/version-check', headers),

    /**
     * Get data about user location.
     * @param {Array} [headers] - Optional request headers
     * @return {Promise}
     */
    getWhoAmI: (headers) => xGet('/whoami', headers),

    /**
     * Get agenda overview.
     * @param {Array} [headers]
     */
    getAgendaOverview: (headers) => xGet('/agenda/overview', headers),

    /**
     * Get votings overview.
     * @param {Array} [headers]
     */
    getVotingsOverview: (headers) => xGet('/agenda/overview/stemmingen', headers),

    /**
     * Get dated components.
     * @param {String} date
     */
    getDatedComponents: (date) => Promise.all([methods.getDatedActors(date), methods.getDatedAgenda(date)]),

    /**
     * Get available video download qualities
     * @param {String} debateId
     */
    getDebateDownloadQualities: (debateId) => xGet(`/debates/${debateId}/qualities`),

    /**
     * Requests all actors (hashmap of parties and politicians)
     * @param  {Array}  [headers] Optional request headers
     * @return {Promise}          Resolves with the actors hashmap
     */
    getActors: (headers) => xGet('/actors', headers),

    /**
     * Get agenda for a specific date.
     * @param {String} date
     * @param {Array} [headers]
     */
    getDatedActors: (date, headers) => xGet(`/actors/${date}`, headers),

    /**
     * Requests all parties
     * @param  {Array}  [headers] Optional request headers
     * @return {Promise}          Resolves with the parties array
     */
    getParties: (headers) => xGet('/actors/parties', headers),

    /**
     * Requests all politicians
     * @param  {Array}  [headers] Optional request headers
     * @return {Promise}          Resolves with the politicians array
     */
    getPoliticians: (headers) => xGet('/actors/politicians', headers),

    // Agenda

    /**
     * Requests all agenda data (hashmap of categories, debates and documents)
     * @param  {Array}  [headers] Optional request headers
     * @return {Promise}          Resolves with the agenda hashmap
     */
    getAgenda: (headers) =>
      xGet('/agenda', headers).then((data) => {
        data.agendaFetchTime = Date.now();

        return data;
      }),

    /**
     * Get agenda for a specific date.
     * @param {String} date
     * @param {Array} [headers]
     */
    getDatedAgenda: (date, headers) =>
      xGet(`/agenda/${date}`, headers).then((data) => {
        data.agendaFetchTime = Date.now();

        return data;
      }),

    /**
     * Get politician data.
     * @param {String} politicianId
     * @param {Array} [headers]
     */
    getPoliticianData: (politicianId, headers) => cachingGet(politiciansCache, politicianId, `/actors/politicians/${politicianId}`, headers),

    /**
     * Requests all categories
     * @param  {Array}  [headers] Optional request headers
     * @return {Promise}          Resolves with the categories array
     */
    getCategories: (headers) => xGet('/agenda/categories', headers),

    /**
     * Requests all debates
     * @param  {Array}  [headers] Optional request headers
     * @return {Promise}          Resolves with the debates array
     */
    getDebates: (headers) => xGet('/agenda/debates', headers),

    /**
     * Removes a specific notification
     * @param  {String} payload.id   Id of the notification to unset
     * @param  {Array}  [headers]    Optional request headers
     * @return {Promise}             Resolves with the notifications array
     */
    deleteDebateNotification: ({ debateId }, headers) => xDelete(`/agenda/debates/${debateId}/notifications`, null, headers),

    /**
     * Requests detailed debate info
     * @param {String} debateDate - The debate date
     * @param {String} debateId - The id for the debate to request
     * @param {Array} [headers] Optional request headers
     * @return {Promise} - Resolves with a debate hashmap
     */
    getDebate: (debateDate, debateId, headers) => xGet(`/agenda/${debateDate}/debates/${debateId}`, headers),

    /**
     * Try to resolve debat gemist URL.
     * @param {String} parameters
     */
    getMissingDebateData: (parameters, headers) => {
      return xGet(`/debatgemist?${parameters}`, headers);
    },

    /**
     * Requests all documents
     * @param  {Array}  [headers] Optional request headers
     * @return {Promise}          Resolves with the documents array
     */
    getDocuments: (headers) => xGet('/agenda/documents', headers),

    // App

    /**
     * Requests all static data (hashmap of faqs, locations and terminology)
     * @param  {Array}  [headers] Optional request headers
     * @return {Promise}          Resolves with the app hashmap
     */
    getApp: (headers) => xGet('/app', headers),

    /**
     * Requests all terminology
     * @param  {Array}  [headers] Optional request headers
     * @return {Promise}          Resolves with the terminology array
     */
    getTerminology: (headers) => xGet('/app/terminology', headers),

    /**
     * Requests all faqs
     * @param  {Array}  [headers] Optional request headers
     * @return {Promise}          Resolves with the faq array
     */
    getFaq: (headers) => xGet('/app/faq', headers),

    /**
     * Requests all locations
     * @param  {Array}  [headers] Optional request headers
     * @return {Promise}          Resolves with the locations array
     */
    getLocations: (headers) => xGet('/locations', headers),

    // Notifications

    /**
     * Requests all notifications from the given user (identifier)
     * @param  {String} deviceToken  Device token
     * @return {Promise}             Resolves with the notifications array
     */
    getNotificationsV1: (deviceToken) => xGet(`/notifications`, [{ name: 'ClientId', value: deviceToken }]),

    /**
     * Removes a specific notification
     * @param  {String} id           Id of the notification to unset
     * @param  {String} deviceToken  Device token
     * @return {Promise}
     */
    deleteNotificationV1: (id, deviceToken) =>
      xDelete(`/notifications/${id}`, null, [
        {
          name: 'ClientId',
          value: deviceToken,
        },
      ]),

    /**
     * Obtain endpoint identifier from the given deviceToken
     * @param  {string} deviceToken   Push notification device token
     * @return {Promise}              Resolves with known endpoint identifier
     */
    obtainEndpointIdentifier: (deviceToken) => xPost(`/v2/endpoint/obtain`, { deviceToken }),

    /**
     * Requests all notifications from the given user (identifier)
     * @param  {string} id      Notifications identifier
     * @return {Promise}        Resolves with the notifications array
     */
    getNotifications: (id) => xGet(`/v2/notifications/${id}`),

    /**
     * Registers a notification
     * @param  {Object|Array|String} payload   The notification data
     * @return {Promise}                       Resolves with the notifications array
     */
    putNotifications: (payload) => xPut('/v2/notifications', payload),

    /**
     * Sends the support form data
     * @param  {Object|Array|String} payload   The form data to submit
     * @param  {Array}               [headers] Optional request headers
     * @return {Promise}                       Resolves without return value
     */
    postSupport: (payload, headers) => xPost('/support', payload, headers),

    /**
     * Get debates across multiple weeks
     * @param {Number} pastWeeks
     * @param {Number} futureWeeks
     * @param {string} date YYYY-MM-DD or `today`
     * @returns {Promise}
     */
    getAgendaWeeks: (pastWeeks = 1, futureWeeks = 1, date = 'today') => xGet(`/agenda/weeks/${date}/${pastWeeks}/${futureWeeks}`),

    /**
     * Get debate summary
     * @param {string} debateId
     * @param {Object} headers
     * @returns {Promise}
     */
    getDebateSummary: (debateId, headers) => xGet(`/debates/${debateId}/summary`, headers),
  };

  return methods;
};

export default api;
