import { formatters } from '@debatdirect/core/common';
import AppView from '@debatdirect/core/containers/AppView/AppView';
import Downloads from '@debatdirect/core/pages/Downloads/Downloads';
import DebateEmbedded from '@debatdirect/core/pages/Embedded/DebateEmbeddedComponent';
import LocationEmbedded from '@debatdirect/core/pages/Embedded/LocationEmbeddedComponent';
import Support from '@debatdirect/core/pages/Support/Support';
import Politicians from '@debatdirect/core/pages/Politicians/Politicians';
import LocationPlenary from '@debatdirect/core/pages/LocationPlenary/LocationPlenary';
import Agenda from '@debatdirect/core/pages/Agenda/Agenda';
import GenericError from '@debatdirect/core/pages/GenericError/GenericError';
import Disclaimer from '@debatdirect/core/pages/Disclaimer/Disclaimer';
import AccessibilityStatement from '@debatdirect/core/pages/AccessibilityStatement/AccessibilityStatement';
import DebateVideo from '@debatdirect/core/pages/DebateVideo/DebateVideo';
import DebateSwitcher from '@debatdirect/core/pages/DebateSwitcher/DebateSwitcher';
import DebateSubject from '@debatdirect/core/pages/DebateSubject/DebateSubject';
import DebateMarkers from '@debatdirect/core/pages/DebateMarkers/DebateMarkers';
import DebateLocation from '@debatdirect/core/pages/DebateLocation/DebateLocation';
import DebateDocuments from '@debatdirect/core/pages/DebateDocuments/DebateDocuments';
import AboutComponent from '@debatdirect/core/pages/About/About';
import Votings from '@debatdirect/core/pages/Votings/Votings';
import Kamerbeelden from '@debatdirect/core/pages/Kamerbeelden/Kamerbeelden';
import Search from '@debatdirect/core/pages/Search/Search';
import SecurityPolicy from '@debatdirect/core/pages/SecurityPolicy/SecurityPolicy';
import LicenseList from '@debatdirect/core/components/LicenseList/LicenseList';
import NotFound from '@debatdirect/core/pages/NotFound/NotFound';
import RedirectHome from '@debatdirect/core/components/RedirectHome/RedirectHome';
import Home from '@debatdirect/core-ts/pages/Home/Home';

export default {
  path: '/',
  component: AppView,
  indexRoute: {
    name: 'home',
    component: Home,
    title: 'De Tweede Kamer',
  },
  childRoutes: [
    {
      name: 'agenda',
      path: 'agenda',
      title: 'Agenda',
      component: Agenda,
      childRoutes: [
        {
          name: 'index-for-date',
          path: ':date',
          component: Agenda,
          title: 'Agenda',
          childRoutes: [
            {
              name: 'category',
              path: ':category',
              component: Agenda,
              title: formatters.formatNavBarTitle,
            },
          ],
        },
      ],
    },
    {
      name: 'votings',
      path: 'stemmingen',
      title: 'Stemmingen van deze week',
      component: Votings,
    },
    {
      name: 'location-plenary',
      path: 'zalen/plenaire-zaal',
      title: 'Over de Plenaire Zaal',
      component: LocationPlenary,
    },
    {
      name: 'parliament-members',
      path: 'personen/kamerleden',
      title: 'Kamerleden',
      component: Politicians,
    },
    {
      name: 'cabinet-members',
      path: 'personen/kabinetsleden',
      title: 'Kabinetsleden',
      component: Politicians,
    },
    {
      name: 'location-embedded',
      path: 'zaal-embedded/:location',
      title: 'Zaal embed',
      component: LocationEmbedded,
    },
    {
      name: 'location-embedded-audio',
      path: 'zaal-embedded-audio/:location',
      title: 'Zaal embed (audio)',
      component: LocationEmbedded,
    },
    {
      name: 'debate',
      path: ':date/:category/:location/:debate',
      component: DebateSwitcher,
      indexRedirect: 'debate-subject',
      childRoutes: [
        {
          path: 'onderwerp',
          name: 'debate-subject',
          component: DebateSubject,
        },
        {
          name: 'debate-video',
          path: 'video',
          component: DebateVideo,
        },
        {
          name: 'debate-markers',
          path: 'markeringen',
          component: DebateMarkers,
        },
        {
          name: 'debate-location',
          path: 'in-de-zaal',
          component: DebateLocation,
        },
        {
          name: 'debate-documents',
          path: 'stukken',
          component: DebateDocuments,
        },
        {
          name: 'debate-embedded',
          path: 'embedded',
          component: DebateEmbedded,
        },
      ],
    },
    {
      name: 'about',
      path: 'about',
      title: 'Over Debat Direct',
      component: AboutComponent,
    },
    {
      name: 'licenses',
      path: 'licenties',
      title: 'Licenties',
      component: LicenseList,
    },
    {
      name: 'accessibility',
      path: 'toegankelijkheid',
      title: 'Toegankelijkheidsverklaring',
      component: AccessibilityStatement,
    },
    {
      name: 'disclaimer',
      path: 'disclaimer',
      title: 'Disclaimer',
      component: Disclaimer,
    },
    {
      name: 'security-policy',
      path: 'security-policy',
      title: 'Security Policy',
      component: SecurityPolicy,
    },
    {
      name: 'support',
      path: 'support/(:faq)',
      title: 'Support',
      component: Support,
    },
    {
      name: 'kamerbeelden',
      path: 'kamerbeelden',
      title: 'Kamerbeelden',
      component: Kamerbeelden,
    },
    {
      name: 'downloads',
      path: 'downloads',
      title: 'Downloads',
      component: Downloads,
    },
    {
      name: 'search',
      path: 'search',
      title: 'Zoek debatten',
      component: Search,
    },
    {
      name: 'not-found',
      path: 'not-found',
      title: 'Niet Gevonden',
      component: NotFound,
    },
    {
      name: 'home-redirect',
      path: 'home',
      component: RedirectHome, // Transitional redirect for the old home route
    },
    {
      name: 'error',
      path: '*',
      title: 'Foutmelding',
      component: GenericError,
    },
  ],
};
