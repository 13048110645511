import React, { type FC, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { format, isToday } from 'date-fns';
import { useAppProvider } from '@debatdirect/core-ts/lib/AppProvider';
import type { AppAgendaDebate } from '@debatdirect/core-ts/types/debate';
import CircularProgress from '@debatdirect/core/components/Progress/CircularProgress';
import Shelf from '@debatdirect/core-ts/components/Shelf/Shelf';
import FeaturedShelf from '@debatdirect/core-ts/components/FeaturedShelf/FeaturedShelf';
import useAgendaWeeks from '@debatdirect/core-ts/hooks/useAgendaWeeks';
import useWatchHistory from '@debatdirect/core-ts/hooks/useWatchHistory';
import useApp from '@debatdirect/core-ts/hooks/useApp';
import { formatRecessTitle, capitalizeFirstLetter } from '@debatdirect/core-ts/utils/formatter';
import Notification from '@debatdirect/core-ts/components/Notification/Notification';
import Button from '@debatdirect/core-ts/components/Button/Button';
import { Search } from '@debatdirect/core/icons';

import {
  groupByDateRange,
  filterTodayByLocation,
  formatDebateDateRangeStr,
  ShelfType,
  filterFeatured,
  createSearchUrl,
  hasDebates,
  TShelf,
} from './utils/shelf';
import styles from './Home.module.scss';

const Home: FC = () => {
  const baseUrl = import.meta.env.REACT_APP_BASE_URL;
  const { getService } = useAppProvider();
  const { data: debates, isLoading } = useAgendaWeeks();
  const { debates: watchHistoryDebates = [], refresh } = useWatchHistory();
  const appQuery = useApp();
  const locations = appQuery?.data?.locations;
  const activeRecess = appQuery.data?.activeRecess;

  const handleClearWatchHistoryClick = (debate: AppAgendaDebate) => {
    getService('continue-watching').removeDebate(debate.id);
    refresh();
  };

  const featuredDebates = useMemo(() => {
    return filterFeatured(debates || []);
  }, [debates]);

  const watchHistoryDict = useMemo(() => {
    return Object.fromEntries(watchHistoryDebates.map((debate) => [debate.id, debate]));
  }, [watchHistoryDebates]);

  const shelves = useMemo(() => {
    const plenaryDebates = filterTodayByLocation(debates || [], 'plenaire-zaal');
    const datedShelves = groupByDateRange(debates || []);
    const plenaryShelves: TShelf[] =
      plenaryDebates.length > 0
        ? [
            {
              title: 'Vandaag in de plenaire zaal',
              shelfType: ShelfType.TODAY_PLENARY,
              debates: plenaryDebates,
              location: 'Plenaire zaal',
              startDate: new Date(),
              endDate: new Date(),
            },
          ]
        : [];

    const shelvesDict = Object.fromEntries([...plenaryShelves, ...datedShelves].map((shelf) => [shelf.shelfType, shelf]));

    // Remove featured items from live shelf
    if (shelvesDict[ShelfType.LIVE]) {
      shelvesDict[ShelfType.LIVE].debates = shelvesDict[ShelfType.LIVE].debates.filter(
        (debate) => ![featuredDebates.large[0], featuredDebates.other[0], featuredDebates.other[1]].includes(debate),
      );
    }

    return shelvesDict;
  }, [debates, featuredDebates]);

  const liveShelf = shelves[ShelfType.LIVE];
  const bottomShelvesMap = [
    ShelfType.SOON,
    ShelfType.CONTINUE_WATCHING,
    ShelfType.EARLIER_TODAY,
    ShelfType.TODAY_PLENARY,
    ShelfType.YESTERDAY,
    ShelfType.EARLIER_THIS_WEEK,
    ShelfType.LAST_WEEK,
  ];

  const hasTodayFeaturedDebate = [...featuredDebates.large, ...featuredDebates.other].some((debate) => isToday(debate.debateDate));
  const hasTodayDebates =
    hasTodayFeaturedDebate || hasDebates(Object.values(shelves), [ShelfType.LIVE, ShelfType.SOON, ShelfType.EARLIER_TODAY, ShelfType.TODAY_PLENARY]);

  return (
    <div className="Layer ViewStack-layer">
      <Helmet>
        <title>Deze week in de Tweede Kamer | Debat Direct</title>
        <link rel="canonical" href={baseUrl} />
      </Helmet>
      <div className="Main-wrapper is-expanded" id="main" tabIndex={-1}>
        <main role="main" className="Main-content Main-content--full Content">
          {isLoading ? (
            <div className={styles.loading}>
              <p>Aan het laden...</p>
              <CircularProgress size={48} />
            </div>
          ) : (
            <>
              <FeaturedShelf debateLeft={featuredDebates.large} debatesRight={featuredDebates.other} locations={locations} />
              {!!activeRecess && (
                <Notification
                  title={formatRecessTitle(activeRecess)}
                  message="U kunt eerdere debatten bekijken via de zoekfunctie"
                  button={<Button label="Zoek debatten" variant="light" iconLeft={Search} href="/search" />}
                />
              )}
              {liveShelf && (
                <Shelf
                  title={liveShelf.title}
                  subtitle={formatDebateDateRangeStr(liveShelf.startDate, liveShelf.endDate)}
                  debates={liveShelf.debates}
                  locations={locations}
                />
              )}
              {!hasTodayDebates && !activeRecess && (
                <Shelf title="Vandaag">
                  <Notification
                    title={capitalizeFirstLetter(`${format(new Date(), 'EEEE d MMMM y')} zijn er geen debatten gepland`)}
                    message="U kunt eerdere debatten bekijken via de zoekfunctie"
                    headingLevel={3}
                    button={<Button label="Zoek debatten" variant="light" iconLeft={Search} href="/search" />}
                  />
                </Shelf>
              )}
              {bottomShelvesMap.map((shelfType) => (
                <React.Fragment key={shelfType}>
                  {shelfType === ShelfType.CONTINUE_WATCHING ? (
                    <>
                      {watchHistoryDebates.length > 0 && <hr aria-hidden="true" className={styles.divider} />}
                      <Shelf
                        title="Kijk verder"
                        debates={watchHistoryDebates}
                        locations={locations}
                        watchHistory={watchHistoryDict}
                        onClearWatchHistoryClick={handleClearWatchHistoryClick}
                      />
                    </>
                  ) : (
                    shelves[shelfType] && (
                      <>
                        <hr aria-hidden="true" className={styles.divider} />
                        <Shelf
                          title={shelves[shelfType].title}
                          subtitle={formatDebateDateRangeStr(shelves[shelfType].startDate, shelves[shelfType].endDate)}
                          link={createSearchUrl(shelves[shelfType].debates, shelves[shelfType].location, shelves[shelfType].status)}
                          debates={shelves[shelfType].debates}
                          locations={locations}
                        />
                      </>
                    )
                  )}
                </React.Fragment>
              ))}
            </>
          )}
        </main>
      </div>
    </div>
  );
};

export default Home;
