import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAppProvider } from '@debatdirect/core-ts/lib/AppProvider';
import useAgendaWeeks from '@debatdirect/core-ts/hooks/useAgendaWeeks';
import { transformDebateData } from '@debatdirect/core-ts/utils/apiTransformers';
import { parseISO } from 'date-fns';
import type { AgendaDebate } from '@debatdirect/typings';
import { AppAgendaDebate } from 'types/debate';

type DebateWatched = {
  pdt: string;
  debateId: string;
  watchMoment: string;
  progress?: number;
};

export type WatchHistoryItem = AppAgendaDebate & { watchHistoryData: DebateWatched };

const MAX_ITEMS = 10;

const useWatchHistory = () => {
  const { getService } = useAppProvider();
  const { data, isLoading } = useAgendaWeeks();
  const api = getService('api');
  const debates = data || [];
  const enabled = !isLoading;
  const [watchHistory, setWatchHistory] = useState<DebateWatched[]>([]);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  useEffect(() => {
    // TODO rewrite without using continue-watching factory
    setWatchHistory(getService('continue-watching').getHistory());
  }, [getService, refreshTrigger]);

  const refresh = () => {
    // TODO make reactive to changes within the watch history instead of using this temporary workaround
    setRefreshTrigger((prev) => prev + 1);
  };

  const fetchDebates = async (): Promise<WatchHistoryItem[]> => {
    const mostRecentlyWatchedItems = watchHistory
      .filter((debate) => !debate.progress || (debate.progress >= 0.05 && debate.progress <= 0.95))
      .sort((a, b) => parseISO(b.watchMoment).getTime() - parseISO(a.watchMoment).getTime())
      .slice(0, MAX_ITEMS);
    const debatesDict = Object.fromEntries(debates.map((debate) => [debate.id, debate]));

    const promises = mostRecentlyWatchedItems.map(async (debateWatched) => {
      const { debateId } = debateWatched;
      if (debatesDict[debateId]) {
        return Promise.resolve(debatesDict[debateId]);
      }
      return api
        .getDebateSummary(debateId)
        .then((debate: AgendaDebate) => transformDebateData(debate))
        .catch(() => {
          return null; // When API fails, filter out the debate
        });
    });

    return (await Promise.all(promises)).filter(Boolean).map((debate) => {
      // Supplement debate with watch history data
      const foundIndex = watchHistory.findIndex((item) => item.debateId === debate.id);
      const historyItem = watchHistory[foundIndex];
      return { ...debate, watchHistoryData: historyItem };
    });
  };

  const debateIds = watchHistory.map((item) => item.debateId);
  const debatesInAgenda = debates.filter((debate) => debateIds.includes(debate.id));
  const { data: watchHistoryDebates } = useQuery({
    enabled,
    queryKey: ['watchHistory', debateIds, debatesInAgenda],
    placeholderData: (prevData) => prevData ?? [],
    queryFn: fetchDebates,
  });

  return {
    debates: watchHistoryDebates,
    refresh,
  };
};

export default useWatchHistory;
