import { useEffect } from 'react';

const RedirectHome = ({ router }) => {
  useEffect(() => {
    router.replace('/');
  }, [router]);

  return null;
};

export default RedirectHome;
