import { type FC } from 'react';
import classNames from 'classnames';
import type { AppAgendaDebate } from '@debatdirect/core-ts/types/debate';
import { format, isToday, isTomorrow, isYesterday, isAfter, isBefore, addDays } from 'date-fns';
import { Calendar, Live } from '@debatdirect/core/icons';

import styles from './StatusTag.module.scss';

type Props = {
  debate: AppAgendaDebate;
  isFeatured?: boolean;
  className?: string;
};

const isBeforeYesterday = (date: string | Date) => isBefore(date, addDays(new Date(), -1));

const formatStatusTag = (debate: AppAgendaDebate, isFeatured: boolean) => {
  if (debate.status === 'live') {
    return { text: 'LIVE', icon: <Live className={styles.live} aria-hidden="true" /> };
  }

  if (isFeatured) {
    const dateShort = format(debate.startsAt, 'd LLL').replace(/\.$/, '');
    switch (true) {
      case isBeforeYesterday(debate.debateDate):
        return { text: dateShort, icon: <Calendar aria-hidden="true" /> };
      case isYesterday(debate.debateDate):
        return { text: `Gisteren`, icon: <Calendar aria-hidden="true" /> };
      case isToday(debate.debateDate):
        return debate.status === 'vod'
          ? { text: debate.duration }
          : { text: `Gepland om ${format(debate.startsAt, 'HH:mm')}`, icon: <Calendar aria-hidden="true" /> };
      case isTomorrow(debate.debateDate):
        return { text: `Morgen om ${format(debate.startsAt, 'HH:mm')}`, icon: <Calendar aria-hidden="true" /> };
      default:
        return { text: `Gepland voor ${dateShort}`, icon: <Calendar aria-hidden="true" /> };
    }
  }

  return debate.status === 'vod' ? { text: debate.duration } : { text: 'Gepland', icon: <Calendar aria-hidden="true" /> };
};

const StatusTag: FC<Props> = ({ debate, className, isFeatured = false }) => {
  const { text, icon } = formatStatusTag(debate, isFeatured);
  return (
    <div className={classNames(styles.status, className)}>
      {icon}
      {text}
    </div>
  );
};

export default StatusTag;
